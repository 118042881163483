import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['custom', 'bluecms'];

  senderKindChanged(event) {
    console.log(this.element)
    console.log(event.target.value)
    if(event.target.value == 'custom'){
      this.bluecmsTarget.classList.add('d-none')
      this.customTarget.classList.remove('d-none')
    } else if(event.target.value == 'bluecms'){
      this.bluecmsTarget.classList.remove('d-none')
      this.customTarget.classList.add('d-none')
    }
  }

}
